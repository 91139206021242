<template>
  <el-card body-style="margin: -20px;" v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :sm="8" :xs="10"
          ><el-row type="flex" justify="start"
            ><h4>{{ $route.name }}</h4></el-row
          ></el-col
        >
        <el-col :md="16" :sm="16" :xs="14">
          <el-row type="flex" justify="end">
            <el-col :md="24">
              <el-row :gutter="4" type="flex" justify="end" align="center">
                <el-button
                  type="primary"
                  icon="el-icon-s-operation"
                  class="mb-0"
                  size="medium"
                  @click="openFilterAnimalsModal()"
                ></el-button>
                <el-button
                  type="primary"
                  class="mb-0"
                  :disabled="true"
                  icon="el-icon-download"
                  :loading="isLoadingDownloadButton"
                  size="medium"
                  @click="downloadReport()"
                ></el-button>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-card shadow="never" class="mt-1">
      <el-descriptions title="Total de Animais" v-if="HasFilters">
        <el-descriptions-item label="Total de Animais">{{
          animals?.length
        }}</el-descriptions-item>
        <el-descriptions-item label="Peso total descrito"
          >{{
            numberFormatter.format(sumAnimalsWeight(animals) || 0)
          }}
          kg.</el-descriptions-item
        >
        <el-descriptions-item label="Peso total aferido"
          >{{
            numberFormatter.format(sumCarcassesWeight(animals) || 0)
          }}
          kg.</el-descriptions-item
        >
        <el-descriptions-item label="Peso médio dos animais"
          >{{
            numberFormatter.format(sumCarcassesWeight(animals) / animals.length)
          }}
          kg.</el-descriptions-item
        >
        <el-descriptions-item label="Média valor animal">
          {{ currencyFormatter.format(getAnimalPriceAvg(animals) || 0) }}
        </el-descriptions-item>
        <el-descriptions-item label="Preço médio por kg.">
          {{
            currencyFormatter.format(
              sumAnimalPrices(animals) / sumCarcassesWeight(animals)
            )
          }}
        </el-descriptions-item>
        <el-descriptions-item label="Valor total">{{
          currencyFormatter.format(sumAnimalPrices(animals) || 0)
        }}</el-descriptions-item>
      </el-descriptions>
      <el-empty v-else description="Insira filtros para obter o relatório">
        <el-button-group>
          <el-button type="primary" @click="updateFiltersFromDays(60)"
            >Últimos 60 dias</el-button
          >
          <el-button type="primary" @click="updateFiltersFromDays(30)"
            >Últimos 30 dias</el-button
          >
          <el-button type="primary" @click="updateFiltersFromDays(15)"
            >Últimos 15 dias</el-button
          >
          <el-button type="primary" @click="updateFiltersFromDays(7)"
            >Últimos 7 dias</el-button
          >
        </el-button-group>
      </el-empty>
      <el-table
        stripe
        :cell-style="() => 'text-align:center;'"
        v-if="HasFilters"
        :data="getAnimalsByStandard(animals)"
        style="width: 100%; z-index: 0"
      >
        <el-table-column label="cabeças" prop="length"></el-table-column>
        <el-table-column label="qualidade">
          <template #default="p">
            {{ p?.row?.length ? p.row[0].standard : "" }}
          </template>
        </el-table-column>
        <el-table-column label="peso aferido">
          <template #default="p">
            {{ `${numberFormatter.format(sumCarcassesWeight(p.row))}` }}
            kg.
          </template>
        </el-table-column>
        <el-table-column label="peso declarado">
          <template #default="p">
            {{ `${numberFormatter.format(sumAnimalsWeight(p.row))}` }}
            kg.
          </template>
        </el-table-column>
        <el-table-column
          label="média de preço"
          :formatter="
            (r) => currencyFormatter.format(Number(getAnimalPriceAvg(r)) || 0)
          "
        ></el-table-column>
        <el-table-column label="valor total">
          <template #default="p">
            {{ `${currencyFormatter.format(sumAnimalPrices(p.row))}` }}
          </template>
        </el-table-column>
      </el-table>
      <el-row v-show="Animals?.data?.length && HasFilters">
        <el-col :md="4">
          <p class="summary summary-black">Total:</p>
        </el-col>
        <el-col :md="4"> </el-col>
        <el-col :md="4">
          <p class="summary summary-black">
            {{ numberFormatter.format(sumCarcassesWeight(animals) || 0) }} kg.
          </p>
        </el-col>
        <el-col :md="4">
          <p class="summary summary-black">
            {{ numberFormatter.format(sumAnimalsWeight(animals) || 0) }} kg.
          </p>
        </el-col>
        <el-col :md="4">
          <p class="summary summary-black">
            {{ currencyFormatter.format(getAnimalPriceAvg(animals) || 0) }}
          </p>
        </el-col>
        <el-col :md="4">
          <p class="summary summary-black">
            {{ currencyFormatter.format(sumAnimalPrices(animals) || 0) }}
          </p>
        </el-col>
      </el-row>
    </el-card>
    <div v-if="HasFilters">
      <el-card
        shadow="never"
        class="mt-1"
        v-for="(p, providerUid) in getAnimalsByProvider()"
        :key="p"
      >
        <el-descriptions :title="getProviderByUid(providerUid)?.name">
          <el-descriptions-item label="Total de Animais">{{
            p?.length
          }}</el-descriptions-item>
          <el-descriptions-item label="Peso total descrito"
            >{{
              numberFormatter.format(sumAnimalsWeight(p) || 0)
            }}
            kg.</el-descriptions-item
          >
          <el-descriptions-item label="Peso total aferido"
            >{{
              numberFormatter.format(sumCarcassesWeight(p) || 0)
            }}
            kg.</el-descriptions-item
          >
          <el-descriptions-item label="Peso médio aferido"
            >{{
              numberFormatter.format(sumCarcassesWeight(p) / p.length || 0)
            }}
            kg.</el-descriptions-item
          >
          <el-descriptions-item label="Média valor animal">
            {{ currencyFormatter.format(getAnimalPriceAvg(p) || 0) }}
          </el-descriptions-item>
          <el-descriptions-item label="Preço por kg.">
            {{
              currencyFormatter.format(
                sumAnimalPrices(p) / sumCarcassesWeight(p) || 0
              )
            }}
          </el-descriptions-item>
          <el-descriptions-item label="Valor total">{{
            currencyFormatter.format(sumAnimalPrices(p) || 0)
          }}</el-descriptions-item>
        </el-descriptions>
        <el-table
          stripe
          :cell-style="() => 'text-align:center;'"
          :data="getAnimalsByStandard(p)"
          style="width: 100%; z-index: 0"
        >
          <el-table-column label="cabeças" prop="length"></el-table-column>
          <el-table-column label="qualidade">
            <template #default="p">
              {{ p?.row?.length ? p.row[0].standard : "" }}
            </template>
          </el-table-column>
          <el-table-column label="peso aferido">
            <template #default="p">
              {{ `${numberFormatter.format(sumCarcassesWeight(p.row))}` }}
              kg.
            </template>
          </el-table-column>
          <el-table-column label="peso declarado">
            <template #default="p">
              {{ `${numberFormatter.format(sumAnimalsWeight(p.row))}` }}
              kg.
            </template>
          </el-table-column>
          <el-table-column
            label="média de preço"
            :formatter="
              (r) => currencyFormatter.format(Number(getAnimalPriceAvg(r)) || 0)
            "
          ></el-table-column>
          <el-table-column label="valor total">
            <template #default="p">
              {{ `${currencyFormatter.format(sumAnimalPrices(p.row))}` }}
            </template>
          </el-table-column>
        </el-table>
        <el-row v-show="Animals?.data?.length">
          <el-col :md="4">
            <p class="summary summary-black">Total:</p>
          </el-col>
          <el-col :md="4"> </el-col>
          <el-col :md="4">
            <p class="summary summary-black">
              {{ numberFormatter.format(sumCarcassesWeight(p) || 0) }} kg.
            </p>
          </el-col>
          <el-col :md="4">
            <p class="summary summary-black">
              {{ numberFormatter.format(sumAnimalsWeight(p) || 0) }} kg.
            </p>
          </el-col>
          <el-col :md="4">
            <p class="summary summary-black">
              {{ currencyFormatter.format(getAnimalPriceAvg(p) || 0) }}
            </p>
          </el-col>
          <el-col :md="4">
            <p class="summary summary-black">
              {{ currencyFormatter.format(sumAnimalPrices(p) || 0) }}
            </p>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <filter-animals-modal
      :showModal="showFilterAnimalsModal"
      :options="filterOptions"
      @close-modal="showFilterAnimalsModal = false"
      @update-filters="updateFilters"
    ></filter-animals-modal>
  </el-card>
</template>

<script>
import FilterAnimalsModal from "./modals/FilterAnimalsModal.vue";
import { ElNotification } from "element-plus";

export default {
  name: "AnimalsReportPage",
  components: {
    FilterAnimalsModal,
  },
  data: () => ({
    hasError: false,
    isLoading: false,
    showBillModal: false,
    showFilterAnimalsModal: false,
    animals: [],
    currentPage: 1,
    isLoadingDownloadButton: false,
    showPackingListCheckModal: false,
    showPackingListAnimalsModal: false,
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
    }),
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    numberFormatter: new Intl.NumberFormat("pt-BR", {
      style: "decimal",
    }),
    partners: null,
    filterOptions: {},
    showPackingListModal: false,
  }),
  watch: {
    currentPage() {
      this.fetchAnimals();
    },
  },
  mounted() {
    this.fetchAnimals();
    this.fetchPartners();
  },
  computed: {
    Partners() {
      return this.partners || [];
    },
    Animals() {
      return { data: this.animals || [] };
    },
    HasFilters() {
      return !!Object.keys(this.filterOptions)?.length;
    },
  },
  methods: {
    updateFiltersFromDays(days) {
      const created_at_start = new Date();
      created_at_start.setDate(created_at_start.getDate() - days);
      created_at_start.setHours(0, 0);

      this.updateFilters({
        ...(this.filterOptions || {}),
        created_at_start,
      });
    },
    getAnimalsByStandard(animals) {
      const a = animals.reduce((t, a) => {
        if (t[a.standard]) t[a.standard].push(a);
        else t[a.standard] = [a];
        return t;
      }, {});

      return Object.values(a || {});
    },
    getAnimalsByProvider() {
      const a = this.Animals?.data?.reduce((t, a) => {
        if (t[a?.provider?.uid]) t[a?.provider?.uid]?.push(a);
        else t[a?.provider?.uid] = [a];
        return t;
      }, {});

      return a;
    },
    getProviderByUid(uid) {
      return this.partners?.find((p) => p.uid === uid) || null;
    },
    sumCarcassesWeight(animals) {
      return animals?.length
        ? animals
            ?.map((a) => a.carcasses)
            ?.flat()
            ?.reduce((t, c) => (t += Number(c.weight)), 0)
        : 0;
    },
    sumAnimalsWeight(animals) {
      return animals?.length
        ? animals?.reduce((t, a) => (t += this.sumWeights(a)), 0)
        : 0;
    },
    getAnimalPrice(animal) {
      return this.sumWeights(animal) * Number(animal.price);
    },
    sumAnimalPrices(animals) {
      return animals?.length
        ? animals?.reduce((t, a) => (t += Number(this.getAnimalPrice(a))), 0)
        : 0;
    },
    getAnimalPriceAvg(animals) {
      return this.sumAnimalPrices(animals) / animals?.length;
    },
    sumWeights(animal) {
      return (Number(animal?.weight) || 0) + (Number(animal?.weight_) || 0);
    },
    changeDownloadButtonStatus() {
      this.isLoadingDownloadButton = !this.isLoadingDownloadButton;
    },
    updateFilters(filters) {
      this.filterOptions = filters || {};
      this.resetPagination();
      this.fetchAnimals();
    },
    resetPagination() {
      this.currentPage = 1;
    },
    openFilterAnimalsModal() {
      this.showFilterAnimalsModal = true;
    },
    getPrice(animals) {
      return animals[0] ? Number(animals[0].price) : 0;
    },
    downloadReport() {
      this.changeDownloadButtonStatus();

      ElNotification.info({
        title: "Gerando o relatório",
        message: "Isso pode levar alguns segundos",
      });

      const url = new URL(`${this.$store.state.apiUrl}animals/report`);
      url.search = new URLSearchParams({
        ...this.filterOptions,
      });
      fetch(url, {
        credentials: "include",
        headers: {
          Accept: "blob",
        },
      })
        .then((response) => {
          if (response.ok) return response.blob();
          else throw response.json();
        })
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Relatório de Animais.pdf`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(async (e) => {
          ElNotification.error({
            title: "Ocorreu um erro",
            message: (await e).message,
          });
        })
        .finally(() => this.changeDownloadButtonStatus());
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(new Date(c));
      else return "desconhecido";
    },
    fetchPartners() {
      const url = new URL(`${this.$store.state.apiUrl}partners`);
      url.search = new URLSearchParams({ is_provider: true });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.partners = json));
    },
    fetchAnimals() {
      if (!this.HasFilters) return;
      this.isLoading = true;
      const url = new URL(`${this.$store.state.apiUrl}animals`);
      url.search = new URLSearchParams({
        //pagination: this.currentPage,
        ...(this.filterOptions || {}),
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => {
          this.animals = json;
        })
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
tr.problem {
  background-color: #fbe9e7;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.summary-black {
  color: black;
}
.summary {
  color: #333;
  font-weight: 700 !important;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", SimSun, sans-serif;
  font-size: 12px;
  text-align: center;
}
.mt-1 {
  margin-top: 20px;
}
</style>
